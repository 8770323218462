import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createEventHandler } from '@wix/yoshi-flow-editor/tpa-settings';
import { EXPERIMENTS } from '../../constants';
import { IEvents, SETTINGS_EVENTS, TabState } from '../../constants/settings-events';
import { plansFixture } from '../../fixtures';
import { createPlansApi, memoizePlansApi } from '../../services';
import { Analytics } from '../../services/analytics';
import { commonBIDataFromFlowAPI } from '../../services/commonBIData';
import { apiHeaders } from '../../services/headers';
import { PlanListInteractions } from '../../types/PlanListFedops';
import { SettingsReader } from '../PackagePicker/DefaultSettingsAdapter';
import { PlanListController } from './PlanListController';
import settingsParams from './settingsParams';

const createController: CreateControllerFn = async (params) => {
  const {
    flowAPI,
    controllerConfig: { setProps, wixCodeApi, appParams, config },
  } = params;

  const demoPlans = config.publicData?.APP?.demoData?.plans ?? plansFixture;

  flowAPI.bi?.updateDefaults(commonBIDataFromFlowAPI(flowAPI));
  const headers = apiHeaders({ Authorization: appParams.instance });
  const api = createPlansApi(flowAPI.httpClient);
  const componentEventHandler = createEventHandler<IEvents>(config.publicData.COMPONENT || {});
  const analytics = new Analytics(wixCodeApi.window);
  const settings = new SettingsReader(flowAPI.settings, settingsParams);
  const pp = new PlanListController(
    setProps,
    memoizePlansApi(api),
    flowAPI,
    wixCodeApi,
    appParams,
    analytics,
    settings,
    demoPlans,
  );

  return {
    async pageReady() {
      flowAPI.fedops.interactionStarted(PlanListInteractions.PageReady);

      if (flowAPI.experiments.enabled(EXPERIMENTS.FIT_TO_CONTENT_HEIGHT)) {
        setProps({ fitToContentHeight: true });
      }

      componentEventHandler.on(SETTINGS_EVENTS.TAB_STATE, (tabState: TabState) => pp.setProps({ tabState }));
      componentEventHandler.on(SETTINGS_EVENTS.ARE_MOBILE_SETTINGS_OPENED, (areMobileSettingsOpened: boolean) =>
        pp.setProps({ areMobileSettingsOpened }),
      );
      componentEventHandler.onReset(() => pp.setProps({ tabState: TabState.REGULAR, areMobileSettingsOpened: false }));

      wixCodeApi.site.onInstanceChanged((e) => (headers.Authorization = e.instance), appParams.appDefinitionId);

      flowAPI.fedops.interactionEnded(PlanListInteractions.PageReady);
      await pp.initialize();
    },
    async updateConfig(_$w, newConfig) {
      componentEventHandler.notify(newConfig.publicData.COMPONENT || {});
      setProps(await pp.fetchPlans());
    },
  };
};

export default createController;
